<template>
  <div class="background-img-2 flx flx-d-column fx-center">
    <div class="text title-container flx j-c-center a-i-center flx-d-column fx-center">
      <h2 class="title-dark">O QUE DESEJA PRODUZIR?</h2>
      <p>SELECIONE O TIPO DE PRODUÇÃO</p>
    </div>
    <div class="fx-center flx-d-column">
      <router-link to="/enviar-audio">
        <div class="fx-center flx-d-column">
          <img src="../assets/img/audio.png" alt="imagem" width="70%" />
          <h2 class="title-dark">ÁUDIO NUGGET</h2>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: "TypeSelect",
};
</script>
